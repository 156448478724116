import Simplebar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

function customScrollbar() {
	const $customScrollbars = $( '.js-custom-scrollbar' );
	if ( !$customScrollbars.length ) { return false; }

	setTimeout( function() {
		$customScrollbars.each( ( i, item ) => {
			const $scrollbar = $( item );
			new Simplebar( $scrollbar[0] );
		} );
	}, 500 );
}

export default customScrollbar;
