/***
 * Module for "Read More" functionality.
****/
const textReadMore = () => {
	const $readMoreWraps = $( '.read-more-content-wrap' );
	if ( !$readMoreWraps.length ) { return false; }
	const expandedClass = 'read-more-content--expanded';

	$readMoreWraps.each( function( i, item ) {
		const $wrap = $( item );
		const $content = $wrap.find( '.read-more-content' );
		const $button = $wrap.find( '.read-more-content__button' );
		const $contentHidden = $wrap.find( '.read-more-content__hidden' );

		$button.on( 'click', function( e ) {
			e.preventDefault();
			const $this = $( this );
			const labelMore = $this.attr( 'data-label-more' );
			const labelLess = $this.attr( 'data-label-less' );
			if ( $content.hasClass( expandedClass ) ) {
				$content.removeClass( expandedClass );
				$contentHidden.slideUp( 300 );
				$this.html( labelMore );
			} else {
				$content.addClass( expandedClass );
				$contentHidden.slideDown( 450 );
				$this.html( labelLess );
			}

		} );
	} );
};

export default textReadMore;
