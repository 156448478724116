function ninjaFormsSelect() {
	$( document ).on( 'nfFormReady', function( e, ninjaform ) {
		const $selects = $( ninjaform.$el ).find( 'select.nf-element' );
		if ( $selects.length ) {
			$selects.each( function( i, item ) {
				const $select = $( item );
				$select[0].addEventListener( 'change', function() {
					if ( this.value ) {
						this.classList.add( 'selected-value' );
					} else {
						this.classList.remove( 'selected-value' );
					}
				} );
			} );
		}
	} );
}

export default ninjaFormsSelect;
