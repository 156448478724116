function customLazyLoad() {
	function checkLazyLoadImages() {
		document.querySelectorAll( '.js-lazy-load' ).forEach( img => {
			if ( img.getBoundingClientRect().left < window.innerWidth && !img.src ) {
				const src = img.getAttribute( 'data-src' );
				if ( src ) {
					img.onload = () => img.classList.add( 'is-loaded' );
					img.src = src;
				}
			}
		} );
	}

	const lazyLoadItems = document.querySelectorAll( '.js-lazy-load' );
	const observer = new IntersectionObserver( ( entries, observer ) => {
		entries.forEach( entry => {
			if ( entry.isIntersecting ) {
				const img = entry.target;
				const src = img.getAttribute( 'data-src' );
				if ( src ) {
					img.onload = () => {
						img.classList.add( 'is-loaded' );
						img.removeAttribute( 'data-src' );
					};
					img.src = src;
					observer.unobserve( img );
				} else {
					img.classList.add( 'is-loaded' );
				}
			}
		} );
	}, {
		rootMargin: '100px 400px 100px 400px',
		threshold: 0
	} );

	lazyLoadItems.forEach( img => observer.observe( img ) );

	setInterval( checkLazyLoadImages, 1500 );
}


export default customLazyLoad;
