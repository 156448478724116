function modalVideo() {
	const $modalVideoBtn = $( '.js-modal-video-btn' );

	if ( !$modalVideoBtn.length ) { return; }

	const $modalVideo = $( '#modal-video' );
	const $video = $modalVideo.find( 'video' );
	const $playBtn = $modalVideo.find( '.js-play-button' );

	$modalVideoBtn.on( 'click', function() {
		const $target = $( this );

		$video
			.attr( 'src', $target.data( 'video-url' ) )
			.attr( 'poster', $target.data( 'poster-url' ) );

		$modalVideo.modal( 'show' );

		playVideo();
	} );

	$modalVideo.on( 'hide.bs.modal', function() {
		pauseVideo();
	} );

	$video.click( function() {
		if ( this.paused ) {
			hidePlayBt();
		} else {
			showPlayBt();
		}
	} );

	$playBtn.click( function() {
		if ( $video[0].paused ) {
			playVideo();
		} else {
			pauseVideo();
		}
	} );

	function playVideo() {
		$video.trigger( 'play' );

		hidePlayBt();
	}

	function pauseVideo() {
		$video.trigger( 'pause' );

		showPlayBt();
	}

	function showPlayBt() {
		$playBtn.fadeIn( 300 );
	}

	function hidePlayBt() {
		$playBtn.fadeOut( 300 );
	}
}

export default modalVideo;
